export type CloudAccountStatus = 'open' | 'closed';

export function isCloudAccountStatus(value: any): value is CloudAccountStatus {
  return value === 'open' || value === 'closed';
}

export type CloudProvider = 'aws' | 'gcp' | 'azure';

export function isCloudProvider(value: any): value is CloudProvider {
  return value === 'aws' || value === 'gcp' || value === 'azure';
}

export interface CloudAccount {
  id: string;
  name: string;
  accountStatus: CloudAccountStatus;
  type: CloudProvider;
  owner: string;
  businessUnit: string;
  updatedOn: string;
  createdOn: string;
}

export interface CloudAccountCostData {
  costData: CloudAccountCost[];
}

export interface CloudAccountCost {
  accountName: string;
  accountType: CloudProvider;
  accountID: string;
  costData: CostRecord[];
}

export type CostRecord = { year: number; month: number; cost: number };

export const isCostRecord = (data: any): data is CostRecord => {
  if (!data || typeof data !== 'object') {
    return false;
  }

  return (
    typeof data.year === 'number' &&
    typeof data.month === 'number' &&
    typeof data.cost === 'number'
  );
};

export const isCostData = (data: any): data is CloudAccountCostData[] => {
  if (!data || !Array.isArray(data)) {
    return false;
  }

  for (const item of data) {
    if (
      !item ||
      typeof item !== 'object' ||
      typeof item.accountName !== 'string' ||
      typeof item.accountType !== 'string' ||
      typeof item.accountID !== 'string' ||
      !Array.isArray(item.costData) ||
      !item.costData.every(isCostRecord)
    ) {
      return false;
    }
  }

  return true;
};

export const isCloudAccountCostData = (
  data: any,
): data is CloudAccountCostData => {
  if (!data.costData || !Array.isArray(data.costData)) {
    return false;
  }

  return isCostData(data.costData);
};
