import {
  createPlugin,
  createApiFactory,
  configApiRef,
  identityApiRef,
  createRoutableExtension,
  createComponentExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { WebAccessibilityClient } from './api/web-accessibility';
import { webAccessibilityApiRef } from '@internal/web-accessibility-common';

export const webAccessibilityPlugin = createPlugin({
  id: 'web-accessibility',
  apis: [
    createApiFactory({
      api: webAccessibilityApiRef,
      deps: { configApi: configApiRef, identityApi: identityApiRef },
      factory: ({ configApi, identityApi }) => {
        return new WebAccessibilityClient({ configApi, identityApi });
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const WebAccessibilityPage = webAccessibilityPlugin.provide(
  createRoutableExtension({
    name: 'WebAccessibilityPage',
    component: () =>
      import('./components/WebAccessibilityPage').then(
        m => m.WebAccessibilityPage,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const EntityWebAccessibilityTab = webAccessibilityPlugin.provide(
  createComponentExtension({
    name: 'EntityWebAccessibilityTab',
    component: {
      lazy: () =>
        import('./components/EntityComponents/EntityWebAccessibilityTab').then(
          m => m.EntityWebAccessibilityTab,
        ),
    },
  }),
);

export const EntityWebAccessibilityOverview = webAccessibilityPlugin.provide(
  createComponentExtension({
    name: 'EntityWebAccessibilityOverview',
    component: {
      lazy: () =>
        import(
          './components/EntityComponents/EntityWebAccessibilityOveriew'
        ).then(m => m.EntityWebAccessibilityOverview),
    },
  }),
);
