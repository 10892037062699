import React from 'react';
import {
  oktaAuthApiRef,
  useApi,
  discoveryApiRef,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';
import { Route } from 'react-router';
import { ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage, NewHomePage } from './components/home';
import { LighthousePage } from '@backstage-community/plugin-lighthouse';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderPage } from '@backstage/plugin-scaffolder';
import { SearchPage, searchPlugin } from '@backstage/plugin-search';
import { TechRadarPage } from './components/tech-radar/TechRadarPage';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  ReportIssue,
  LightBox,
  TextSize,
  ExpandableNavigation,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import { orgPlugin } from '@backstage/plugin-org';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes, AppRouter, FeatureFlagged } from '@backstage/core-app-api';
import { setTokenCookie } from './util/setTokenCookie';
import { EntityMetricsPage } from '@internal/plugin-entity-metrics';
import { rvohDarkTheme } from './themes/rvoh';
import { shortcutsPlugin } from '@backstage-community/plugin-shortcuts';
import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';
import { GithubOrgPickerFieldExtension } from './scaffolder/GitHubOrgPicker';
import { GitHubTeamPickerFieldExtension } from './scaffolder/GitHubTeamPicker';
import { GithubRepoPickerFieldExtension } from './scaffolder/GitHubRepoPicker';
import { InfraRepoPickerFieldExtension } from './scaffolder/InfraRepoPicker';
import { InfraEnvPickerFieldExtension } from './scaffolder/InfraEnvPicker';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { explorePage } from './components/explore/ExplorePage';
import { SoundcheckRoutingPage } from '@spotify/backstage-plugin-soundcheck';
import { SkillExchangePage } from '@spotify/backstage-plugin-skill-exchange';
import { UnifiedThemeProvider } from '@backstage/theme';
import {
  InsightsPage,
  insightsPlugin,
  InsightsSurveyLoader,
} from '@spotify/backstage-plugin-insights';
import { InfraConfigReadFieldExtension } from './scaffolder/InfraConfigRead/InfraConfigRead';
import { AutoLogout } from '@backstage/core-components';
import { RBACRoot } from '@spotify/backstage-plugin-rbac';
import { customCatalogColumnsFunc } from './catalog/columns/customCatalogColumns';
import { CloudAccountsPage } from '@internal/cloud-accounts';
import { MyCloudCostPage } from './components/me/cloud-cost';
import { WebAccessibilityPage } from '@internal/web-accessibility';
import { NotificationsPage, TeamsPage } from '@internal/plugin-teams';
import { PlatformDocsPage } from '@internal/platform-docs';
import {
  RELATION_HAS_CLOUD_ACCOUNT_ACCESS_FROM,
  RELATION_HAS_CLOUD_ACCOUNT_ACCESS_TO,
} from '@internal/cloud-accounts-common';

const app = createApp({
  apis,
  themes: [
    {
      id: 'rvoh-dark',
      title: 'RVO Health Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={rvohDarkTheme} children={children} />
      ),
    },
  ],
  plugins: [shortcutsPlugin],
  featureFlags: [
    // https://backstage.io/docs/plugins/feature-flags/
    {
      pluginId: '',
      name: 'new-homepage',
      description: 'New homepage layout',
    },
  ],
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          auto
          provider={{
            id: 'okta-auth-provider',
            title: 'Okta',
            message: 'Sign in using Okta',
            apiRef: oktaAuthApiRef,
          }}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );
            // Forward results
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    // TODO: fix this
    // bind(apiDocsPlugin.externalRoutes, {
    //   createComponent: scaffolderPlugin.routes.root,
    // });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(insightsPlugin.externalRoutes, {
      searchPageRouteRef: searchPlugin.routes.root,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <FeatureFlagged with="new-homepage">
        <NewHomePage />
      </FeatureFlagged>
      <FeatureFlagged without="new-homepage">
        <HomePage />
      </FeatureFlagged>
    </Route>
    <Route path="/me/cloud-cost" element={<MyCloudCostPage />} />
    <Route
      path="/catalog"
      element={<CatalogIndexPage columns={customCatalogColumnsFunc} />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          relationPairs={[
            [
              RELATION_HAS_CLOUD_ACCOUNT_ACCESS_FROM,
              RELATION_HAS_CLOUD_ACCOUNT_ACCESS_TO,
            ],
          ]}
          initialState={{
            selectedKinds: [
              'component',
              'domain',
              'system',
              'api',
              'group',
              'resource',
            ],
            selectedRelations: [
              RELATION_OWNER_OF,
              RELATION_OWNED_BY,
              RELATION_CONSUMES_API,
              RELATION_API_CONSUMED_BY,
              RELATION_PROVIDES_API,
              RELATION_API_PROVIDED_BY,
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
              RELATION_HAS_CLOUD_ACCOUNT_ACCESS_FROM,
              RELATION_HAS_CLOUD_ACCOUNT_ACCESS_TO,
            ],
          }}
        />
      }
    />
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <LightBox />
        <TextSize />
        <ExpandableNavigation />
        <Mermaid config={{ theme: 'dark' }} />
      </TechDocsAddons>
    </Route>
    <Route path="/lighthouse" element={<LighthousePage />} />
    <Route path="/create" element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <GithubOrgPickerFieldExtension />
        <GithubRepoPickerFieldExtension />
        <GitHubTeamPickerFieldExtension />
        <InfraRepoPickerFieldExtension />
        <InfraEnvPickerFieldExtension />
        <InfraConfigReadFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route path="/catalog-import" element={<CatalogImportPage />} />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/entity-metrics" element={<EntityMetricsPage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/tools" element={<ExplorePage />}>
      {explorePage}
    </Route>
    <Route
      path="/skill-exchange"
      element={<SkillExchangePage enableMentorships />}
    />
    <Route path="/insights" element={<InsightsPage />} />
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/soundcheck" element={<SoundcheckRoutingPage />} />
    <Route path="/rbac" element={<RBACRoot />} />
    <Route path="/cloud-accounts" element={<CloudAccountsPage />} />
    <Route path="/web-accessibility" element={<WebAccessibilityPage />} />
    <Route path="/notifications" element={<NotificationsPage />} />
    <Route path="/teams" element={<TeamsPage />} />
    <Route path="/platform-docs" element={<PlatformDocsPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AutoLogout />
    <AppRouter>
      <InsightsSurveyLoader />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
