import { createApiRef } from '@backstage/core-plugin-api';
import {
  Project,
  ProjectOwner,
  ProjectFilters,
} from '@internal/web-accessibility-common';

export const webAccessibilityApiRef = createApiRef<WebAccessibilityApi>({
  id: 'plugin.web-accessibility',
});

export interface WebAccessibilityApi {
  getProjects(filters?: ProjectFilters): Promise<Project[]>;
  upsertProjectOwner(projectOwner: ProjectOwner): Promise<void>;
}
